import { defineMessages } from 'react-intl';

import { DATE_FROM, DATE_TO } from '../../../common/constants';

export default defineMessages({
  choose: {
    id: 'common.choose',
    defaultMessage: 'Choose...',
  },
  today: {
    id: 'common.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'common.yesterday',
    defaultMessage: 'Yesterday',
  },
  [DATE_FROM]: {
    id: 'common.datesRange.from',
    defaultMessage: 'From',
  },
  [DATE_TO]: {
    id: 'common.datesRange.to',
    defaultMessage: 'To',
  },
  from: {
    id: 'common.datesRange.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'common.datesRange.to',
    defaultMessage: 'To',
  },
  exportEvents: {
    id: 'common.features.export.download_events',
    defaultMessage: 'Download events',
  },
  reset: {
    id: 'common.reset',
    defaultMessage: 'Reset',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
  },
  type: {
    id: 'common.type',
    defaultMessage: 'Type',
  },
  and: {
    id: 'common.and',
    defaultMessage: 'and',
  },
  more: {
    id: 'common.more',
    defaultMessage: 'more',
  },
  time: {
    id: 'common.time',
    defaultMessage: 'Time',
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Date',
  },
  timestamp: {
    id: 'common.timestamp',
    defaultMessage: 'Timestamp',
  },
  startDate: {
    id: 'common.startDate',
    defaultMessage: 'Start Date',
  },
  duration: {
    id: 'common.duration',
    defaultMessage: 'Duration',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
  },
  archive: {
    id: 'common.archive',
    defaultMessage: 'Archive',
  },
  update: {
    id: 'common.update',
    defaultMessage: 'Update',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
  },
  rename: {
    id: 'common.rename',
    defaultMessage: 'Rename',
  },
  yes: {
    id: 'common.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'common.no',
    defaultMessage: 'No',
  },
  unsubscribe: {
    id: 'common.unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  clone: {
    id: 'common.clone',
    defaultMessage: 'Clone',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  back: {
    id: 'common.back',
    defaultMessage: 'Back',
  },
  newDate: {
    id: 'common.newDate',
    defaultMessage: 'New Date',
  },
  publish: {
    id: 'common.publish',
    defaultMessage: 'Publish',
  },
  notSet: {
    id: 'common.notSet',
    defaultMessage: 'Not Set',
  },
  chooseLocation: {
    id: 'common.choose_location',
    defaultMessage: 'Choose Location',
  },
  duplicate: {
    id: 'common.duplicate',
    defaultMessage: 'Duplicate',
  },
  done: {
    id: 'common.done',
    defaultMessage: 'Done',
  },
  empty: {
    id: 'common.empty',
    defaultMessage: 'Empty',
  },
  selectDatePeriod: {
    id: 'common.datepicker.selectDatePeriod',
    defaultMessage: 'Select Date Period',
  },
  loadMore: {
    id: 'common.button.load_more',
    defaultMessage: 'Load more',
  },
  download: {
    id: 'common.download',
    defaultMessage: 'Download',
  },
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
  },
  history: {
    id: 'common.history',
    defaultMessage: 'History',
  },
  start: {
    id: 'common.start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'common.end',
    defaultMessage: 'End',
  },
  responsible: {
    id: 'common.responsible',
    defaultMessage: 'Responsible',
  },
  status: {
    id: 'common.status',
    defaultMessage: 'Status',
  },
  experiment: {
    id: 'common.experiment',
    defaultMessage: 'Experiment',
  },
  dates: {
    id: 'common.dates',
    defaultMessage: 'Dates',
  },
  selectAll: {
    id: 'common.select_all',
    defaultMessage: 'Select All',
  },
  ok: {
    id: 'common.ok',
    defaultMessage: 'OK',
  },
  import: {
    id: 'common.import',
    defaultMessage: 'Import',
  },
  export: {
    id: 'common.export',
    defaultMessage: 'Export',
  },
  importDataPoints: {
    id: 'common.import_data_points',
    defaultMessage: 'Import DataPoints',
  },
  exportDataPoints: {
    id: 'common.export_data_points',
    defaultMessage: 'Export DataPoint',
  },
  commandHistory: {
    id: 'common.commandHistory',
    defaultMessage: 'Command History',
  },
  stop: {
    id: 'common.stop',
    defaultMessage: 'Stop',
  },
  activate: {
    id: 'common.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'common.deactivate',
    defaultMessage: 'Deactivate',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  collect: {
    id: 'common.collect',
    defaultMessage: 'Collect',
  },
  submit: {
    id: 'common.submit',
    defaultMessage: 'Submit',
  },
  enable: {
    id: 'common.enable',
    defaultMessage: 'Enable',
  },
  disable: {
    id: 'common.disable',
    defaultMessage: 'Disable',
  },
  upload: {
    id: 'common.upload',
    defaultMessage: 'Upload',
  },
  generate: {
    id: 'common.generate',
    defaultMessage: 'Generate',
  },
});
