import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommandState } from './GenerateFileModal';
import commonMessages from '../../../../messages/common.messages';

interface ActionFooterButtonsProps {
  commandState: CommandState;
  download: () => void;
  onClose: () => void;
}

export const ActionFooterButtons: React.FC<ActionFooterButtonsProps> = ({
  commandState,
  download,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const buttonText =
    commandState === 'default' || commandState === 'loading'
      ? formatMessage(commonMessages.cancel)
      : formatMessage(commonMessages.close);

  return (
    <>
      <div className='text-center'>
        <button type='button' className='button__cancel' onClick={onClose}>
          {buttonText}
        </button>
        {commandState === 'default' && (
          <button type='button' className='button__save' onClick={download}>
            <FormattedMessage
              defaultMessage={formatMessage(commonMessages.generate)}
              id='common.generate'
            />
          </button>
        )}
      </div>
    </>
  );
};
