import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DATATRONS_PERMISSIONS } from '../../../../../../../../../common/constants/permissions';
import { Todo, TodoFunction } from '../../../../../../../../../common/types/common';
import { useOpen } from '../../../../../../../core/common/hooks/useOpen';
import commonMessages from '../../../../../../../messages/common.messages';
import commonCoreMessages from '../../../../../../../messages/common.core.messages';
import {
  DP_ARCHIVE_DEVICE,
  DP_COMMAND_HISTORY,
  DP_EDIT_DEVICE,
  DP_IMPORT_ACTION,
  DP_QUICK_COMMAND,
  DP_DOWNLOAD_FILE,
  DP_UPLOAD_FILE,
  DP_GENERATE_FILE,
} from '../../../../../../../redux/constants/modals.constants';
import { fetchCommandHistory } from '../../../../../../../redux/modules/datatron.devices.command.module';
import { fetchDataPoints } from '../../../../../../../redux/modules/datatron.devices.dataPoints.fetch.module';
import { openModal } from '../../../../../../../redux/modules/modals.module';
import ThreeDotsMenu from '../../../../../../_tools/threeDotsMenu/ThreeDotsMenu';
import PermissionedComponent from '../../../../../../common/permissions/PermissionedComponent.container';
import { ActivateDeviceModal } from '../../../../../modals';

type Props = {
  isOpen: boolean;
  toggle: TodoFunction;
  device: Todo;
  permissions: Todo;
};

type MenuItem = {
  onClick: () => void;
  label: string;
  isIcon: boolean;
  icon?: string;
  isBold?: boolean;
  visible: boolean;
};

export const DeviceMenu: React.FC<Props> = ({ toggle, isOpen, device, permissions }) => {
  const canImportDevice = permissions.canManageDatatronDevices(); //TODO add permissions
  const canViewCommandHistory = permissions.canManageDatatronDevices(); //TODO add permissions
  const canSendCollect = permissions.canManageDatatronDevices(); //TODO add permissions
  const canSendStop = permissions.canManageDatatronDevices(); //TODO add permissions
  const canActivateDevice = permissions.canManageDatatronDevices(); //TODO add permissions
  const canDownloadDeviceDataPoints = permissions.canManageDatatronDevices(); //TODO add permissions
  const canUploadDeviceDataPoints = permissions.canManageDatatronDevices(); //TODO add permissions
  const canUpdateDevice = permissions.canUpdateDatatronDevices();
  const canDeleteDevice = permissions.canDeleteDatatronDevices();
  const canPublishDevice = permissions.canManageDatatronDevices();
  const canDownloadDeviceConfig = device.deviceType && device.deviceType.downloadEnabled;

  const { id: deviceId } = device;
  const dispatch = useDispatch();
  const openEditModal = () => dispatch(openModal(DP_EDIT_DEVICE, { device }));
  const openArchiveModal = () => dispatch(openModal(DP_ARCHIVE_DEVICE, { deviceId }));

  const openPublishModal = () =>
    dispatch(openModal(DP_QUICK_COMMAND, { deviceId: deviceId, command: 'publish' }));
  const triggerImport = () => dispatch(openModal(DP_IMPORT_ACTION, { deviceId }));
  const openCollectModal = () =>
    dispatch(openModal(DP_QUICK_COMMAND, { deviceId: deviceId, command: 'collect' }));
  const openStopModal = () =>
    dispatch(openModal(DP_QUICK_COMMAND, { deviceId: deviceId, command: 'stop' }));
  const openCommandHistory = () => {
    dispatch(openModal(DP_COMMAND_HISTORY, { deviceId }));
    dispatch(fetchCommandHistory(deviceId, { offset: 0, length: 10 }));
  };

  const toggleExpand = () => {
    dispatch(fetchDataPoints(deviceId, false));
    toggle();
  };

  const openConfirmDownloadModal = useCallback(() => {
    dispatch(openModal(DP_DOWNLOAD_FILE, { deviceId }));
  }, [dispatch, deviceId]);

  const openUploadModal = useCallback(() => {
    dispatch(openModal(DP_UPLOAD_FILE, { deviceId }));
  }, [dispatch, deviceId]);

  const openGenerateFileModal = useCallback(() => {
    dispatch(openModal(DP_GENERATE_FILE, { deviceId }));
  }, [dispatch, deviceId]);

  const { formatMessage } = useIntl();

  const {
    isOpen: isActivateModalOpen,
    close: closeActivateModal,
    open: openActivateModal,
  } = useOpen();

  const menuItems: MenuItem[] = [
    {
      onClick: openEditModal,
      label: formatMessage(commonMessages.edit),
      isIcon: true,
      icon: 'fa fa-pencil',
      visible: canUpdateDevice,
    },
    {
      onClick: openArchiveModal,
      label: formatMessage(commonMessages.archive),
      isIcon: true,
      icon: 'fa fa-archive',
      visible: canDeleteDevice,
    },
    {
      onClick: openActivateModal,
      label: device.active
        ? formatMessage(commonMessages.deactivate)
        : formatMessage(commonMessages.activate),
      isBold: false,
      isIcon: true,
      icon: device.active ? 'fa fa-toggle-off' : 'fa fa-toggle-on',
      visible: canActivateDevice,
    },
    {
      onClick: triggerImport,
      label: formatMessage(commonMessages.import),
      isBold: false,
      isIcon: true,
      visible: canImportDevice,
      icon: 'fa fa-cloud-upload',
    },
    {
      onClick: openGenerateFileModal,
      label: formatMessage(commonMessages.generate),
      isBold: false,
      isIcon: true,
      icon: 'fa fa-file-text-o',
      visible: canDownloadDeviceConfig,
    },
    {
      onClick: openCommandHistory,
      label: formatMessage(commonMessages.commandHistory),
      isBold: false,
      isIcon: true,
      visible: canViewCommandHistory,
      icon: 'fa fa-list-alt',
    },
    {
      onClick: openPublishModal,
      label: formatMessage(commonMessages.publish),
      isBold: true,
      isIcon: true,
      icon: 'fa fa-share',
      visible: canPublishDevice,
    },
    {
      onClick: openCollectModal,
      label: formatMessage(commonMessages.collect),
      isBold: false,
      isIcon: true,
      visible: canSendCollect,
      icon: 'fa fa-play',
    },
    {
      onClick: openStopModal,
      label: formatMessage(commonMessages.stop),
      isBold: false,
      isIcon: true,
      visible: canSendStop,
      icon: 'fa fa-stop-circle',
    },

    {
      onClick: openConfirmDownloadModal,
      label: `${formatMessage(commonMessages.export)} ${formatMessage(commonCoreMessages.dataPoints)}`,
      isBold: false,
      isIcon: true,
      icon: 'fa fa-download',
      visible: canDownloadDeviceDataPoints,
    },
    {
      onClick: openUploadModal,
      label: `${formatMessage(commonMessages.import)} ${formatMessage(commonCoreMessages.dataPoints)}`,
      isBold: false,
      isIcon: true,
      icon: 'fa fa-upload',
      visible: canUploadDeviceDataPoints,
    },
  ];

  const filteredMenuItems = menuItems.filter((item) => item.visible);

  const hasAccess = filteredMenuItems.length > 0;
  const toggleButtonClassName = classnames('icon', isOpen ? 'icon-caret-down' : 'icon-caret-left');

  return (
    <div className='d-flex align-items-center'>
      <span className='button__toggle mr-10' onClick={toggleExpand}>
        <i className={toggleButtonClassName} />
      </span>
      {hasAccess && <ThreeDotsMenu menuItems={filteredMenuItems} />}
      <ActivateDeviceModal
        isActivated={device.active}
        datatronId={device.datatronId}
        deviceId={deviceId}
        isOpen={isActivateModalOpen}
        close={closeActivateModal}
      />
    </div>
  );
};

export default PermissionedComponent(DATATRONS_PERMISSIONS)(DeviceMenu);
